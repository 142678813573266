import MaisonPNG from "../assets/maison-xav-def.png";
import { isMobile } from "../constants/constants";
import { FOOTER_HEIGHT, FOOTER_HEIGHT_MOBILE } from "../constants/sizes";
import { colors } from "../constants/colors";

export const Footer = () => {
  return (
    <footer
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "16px 0",
        boxSizing: "border-box",
        backgroundColor: colors.pink,
      }}
    >
      <img
        alt="la maison"
        src={MaisonPNG}
        style={{ maxHeight: isMobile ? FOOTER_HEIGHT_MOBILE : FOOTER_HEIGHT }}
      />
    </footer>
  );
};
