import { isMobile } from "../constants/constants";
import { Navbar } from "./Navbar";
import { BurgerMenu } from "./BurgerMenu";
import { Header } from "./Header";
import { Confirm } from "../routes/index";
import { useLocation } from "react-router-dom";
import { Footer } from "./Footer";
import { MainContent } from "./MainContent";
import {
  FOOTER_HEIGHT,
  FOOTER_HEIGHT_MOBILE,
  HEADER_HEIGHT,
  HEADER_HEIGHT_MOBILE,
  NAVBAR_HEIGHT,
} from "../constants/sizes";

export function Layout({ children }) {
  const location = useLocation();

  const isConfirmPage = location.pathname === "/confirm";

  const gridTemplateRows = `
  ${isMobile ? 0 : NAVBAR_HEIGHT}px 
  ${isMobile ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT}px 
    1fr 
     ${isConfirmPage ? 0 : isMobile ? FOOTER_HEIGHT_MOBILE : FOOTER_HEIGHT}px `;

  return (
    <div
      className="App-bg"
      style={{
        height: "100svh",
        // backgoundImage: `url(${BG})`,
      }}
    >
      {/*<img src={BG} style={{ position: "absolute" }} />*/}
      <div
        id="container"
        style={{
          maxWidth: isMobile ? "100%" : "768px",
          display: "grid",
          gridTemplateRows: gridTemplateRows,
        }}
      >
        {isMobile ? <BurgerMenu /> : <Navbar />}
        <Header />
        {location.pathname === "/confirm" ? (
          <Confirm />
        ) : (
          <MainContent $isMobile={isMobile}>{children}</MainContent>
        )}
        {location.pathname !== "/confirm" && <Footer />}
      </div>
    </div>
  );
}
