import BoulafVecto from "../assets/BoulafVecto.png";

export const Jukebox = () => (
  <div
    style={{
      height: "100%",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    }}
  >
    <img
      src={BoulafVecto}
      alt="une boule à facette"
      style={{ width: 150, marginTop: 16, marginBottom: 80 }}
    />
    <span className="home-subtitle">SOON ...</span>
  </div>
);
