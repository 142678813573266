import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

export const Login = ({ setAuthenticated }) => {
  const [userInput, setUserInput] = useState("")
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false)
  const navigate = useNavigate()
  const hiddenPassword = process.env.REACT_APP_WEBSITE_PASSWORD
  const testBaseId = process.env.REACT_APP_AIRTABLE_WEDDING_BASE_ID
  const localStorage = window.localStorage
  const storedPassword = localStorage.getItem("pwd") ?? null

  const handleSubmit = () => {
    if (userInput === hiddenPassword) {
      console.log("userInput", userInput)
      setAuthenticated(true)
      localStorage.setItem("pwd", hiddenPassword)
      navigate("/home")
    } else {
      setDisplayErrorMessage(true)
    }
  }

  useEffect(() => {
    if (storedPassword) {
      setUserInput(storedPassword)
    }
  }, [storedPassword])

  useEffect(() => {
    console.log("testBaseId", testBaseId)
    console.log("hiddenPassword", hiddenPassword)
  }, [testBaseId, hiddenPassword])
  return (
    <>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 70,
        }}
      >
        <span
          className="cool-title"
          style={{
            marginBottom: 16,
            fontFamily: "Dosis",
            fontWeight: "bolder",
            fontSize: 18,
          }}
        >
          LE MOT DE PASSE
        </span>
        <input
          onChange={(e) => setUserInput(e.target.value)}
          style={{ marginBottom: 12, width: 110 }}
          type="password"
          value={userInput}
        />
        <button type="submit " style={{ width: 70 }}>
          Valider
        </button>
        {displayErrorMessage ? (
          <span style={{ color: "red", fontStyle: "italic", fontSize: 12 }}>
            Ce n'est pas le bon mot de passe
          </span>
        ) : null}
      </form>
      {/*<strong>Test</strong>*/}
      {/*<button onClick={sendMessage}>Send</button>*/}
    </>
  )
}
