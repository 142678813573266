import { isMobile } from "../constants/constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Dessin from "../assets/dessin-xav.png";
import styled from "styled-components";
import {
  HEADER_HEIGHT,
  HEADER_HEIGHT_MOBILE,
  HEADER_IMG_HEIGHT,
  HEADER_IMG_HEIGHT_MOBILE,
} from "../constants/sizes";

export const Header = () => {
  return (
    <StyledHeader $isMobile={isMobile}>
      <LazyLoadImage
        alt="Anna & Victoria"
        effect="opacity"
        height={isMobile ? HEADER_IMG_HEIGHT_MOBILE : HEADER_IMG_HEIGHT}
        src={Dessin}
      />
      <span
        style={{
          fontFamily: "Dosis",
          marginTop: -4,
          marginBottom: isMobile ? 0 : 4,
        }}
      >
        Anna & Victoria
      </span>
      <span style={{ fontFamily: "Dosis", marginBottom: 4 }}>
        31 août <span style={{ textTransform: "uppercase" }}>2o24</span>
      </span>
      {/*<>{"isMobile:" + isMobile}</>*/}
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  //height: ${(props) =>
    props.$isMobile ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT};
  padding: ${(props) => (props.$isMobile ? "12px 0" : "0 0 16px 0")};
  box-sizing: border-box;
`;
