import { useEffect, useState } from "react";

export function Confirm() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }, []);

  return (
    <div
      style={{
        height: "99%",
        opacity: isLoading ? 0 : 1,
        transition: "opacity ease-in 0.5s",
      }}
    >
      <iframe
        frameborder="0"
        height="100%"
        title="Confirmez votre présence"
        marginheight="0"
        marginwidth="0"
        src="https://docs.google.com/forms/d/e/1FAIpQLSfNHBja62QtESrZRrft-e203vNSdgmloHr_zNyCtMIN3F-PdQ/viewform?embedded=true"
        width="100%"
      >
        Chargement…
      </iframe>
    </div>
  );
}
