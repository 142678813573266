import { isMobile } from "../constants/constants";

export function Adresses() {
  // Lieu et adresse, accès, parking ok, petit chandail, locs voitur

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: isMobile ? 8 : 32,
          marginTop: isMobile ? 16 : 0,
        }}
      >
        <span className="home-subtitle">LA MAIRIE</span>
        <span>Mairie de Neuville-sur-Touques</span>
        <span>Le Bourg, 61120 Neuville-sur-Touques</span>
        <h4
          className="h4-title"
          style={{ fontFamily: "Barlow Semi Condensed", fontSize: 18 }}
        >
          PARKING
        </h4>
        <span>Vous pourrez garer vos voitures à côté de la Mairie</span>
      </div>
      <hr
        style={{
          height: "1px",
          width: "20%",
          marginBottom: isMobile ? 20 : 40,
        }}
      />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span className="home-subtitle">LA MAISON</span>
        <span>La Germanière</span>
        <span>61120 Neuville-sur-Touques</span>

        <h4
          className="h4-title"
          style={{ fontFamily: "Barlow Semi Condensed", fontSize: 18 }}
        >
          PARKING
        </h4>
        <span>Vous pourrez garer vos voitures à l'entrée de la maison</span>
      </div>
    </>
  );
}
