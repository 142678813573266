import { isMobile } from "../constants/constants";

const Section = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        fontSize: 18,
        width: isMobile ? "100%" : 500,
        //TODO
        // fontFamily: "Sofia Sans",

        // marginTop: 70,
        // border: "solid 1px red",
      }}
    >
      {children}
    </div>
  );
};

const Row = ({ title, children }) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        marginTop: 8,
        // alignItems: "end",
      }}
    >
      <div
        style={{
          width: isMobile ? 120 : 80,
          display: "flex",
          marginRight: isMobile ? 0 : 16,
          // marginLeft: 24,
        }}
      >
        {/* TIME */}
        <span
          style={{
            fontFamily: "Barlow Semi Condensed",
            fontWeight: 600,
            textTransform: "uppercase",
            fontSize: 21,
          }}
        >
          {title}
        </span>
      </div>
      <div
        style={{
          textAlign: "start",
          width: "100%",
        }}
      >
        {children}
      </div>
    </div>
  );
};

// TODO: voir si on peut étendre la classe home-subtitle

export function Home() {
  return (
    <>
      {/*<MainContent $isMobile={isMobile}>*/}
      <Section>
        {/*<div style={{display:"flex", flexDirection: "column"}}>*/}
        <span style={{ marginTop: 32 }}>
          Nous sommes heureuses de vous annoncer notre mariage.
        </span>
        <span>Rendez-vous l'été prochain en Normandie !</span>
        {/*</div>*/}
      </Section>
      <Section>
        <span
          className="home-subtitle"
          style={{
            marginTop: isMobile ? 32 : 64,
          }}
        >
          samedi 31 août 2o24
        </span>
        <Row title="16h30 :">
          <span> cérémonie à la Mairie de Neuville-sur-Touques</span>
        </Row>
        <Row title="17h30 :">
          <span> cocktail à la Germanière, suivi d'un dîner</span>
        </Row>
      </Section>
      <Section>
        <span
          className="home-subtitle"
          style={{
            marginTop: isMobile ? 32 : 64,
            textTransform: "uppercase",
          }}
        >
          dimanche 1er septembre 2024
        </span>
        <Row title="11h30 :">
          <span> brunch dans le jardin de la Germanière</span>
        </Row>
      </Section>
    </>
  );
}
