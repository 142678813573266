import { Row } from "../components/Row";
import { isMobile } from "../constants/constants";

const Link = ({ label, url }) => {
  return (
    <a
      className="body-link"
      href={url}
      rel="noreferrer"
      style={{
        marginRight: 4,
        marginLeft: 4,
        fontFamily: "Sofia Sans",
        fontSize: 17,
      }}
      target="_blank"
    >
      {label}
    </a>
  );
};

//TODO: create component

const SectionWrapper = ({ children }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginBottom: 12,
    }}
  >
    {children}
  </div>
);

export const Infos = () => {
  return (
    <>
      <SectionWrapper>
        <span className="home-subtitle">VENIR</span>
        <span
          style={{
            textTransform: "uppercase",
            marginBottom: 12,
            fontFamily: "Dosis",
            fontWeight: "bolder",
          }}
        >
          Neuville-sur-Touques
        </span>
        <Row title="Voiture :">
          <span>2h45 du centre de Paris</span>
        </Row>
        <Row title="Train :">
          <span>départ de Paris Montparnasse</span>
          <span>direction Granville, arrêt L'Aigle</span>
        </Row>
        {/*<Row title="Taxi :">*/}
        {/*  <span>L'Aigle - Neuville-sur-Touques</span>*/}
        {/*  <span>30mn, environ 70€</span>*/}
        {/*</Row>*/}
        <Row title="Avion :">
          <span>aéroport de Caen</span>
        </Row>
      </SectionWrapper>
      {/*SECTION*/}
      <hr
        style={{
          width: "20%",
          marginBottom: 16,
          // marginBottom: isMobile ? 12 : 24,
          // margin: isMobile ? "8% 0" : "4% 0",
        }}
      />
      <SectionWrapper
      // marginBottom: isMobile ? 8 : 16,
      >
        <span className="home-subtitle">DORMIR</span>
        <span style={{ marginBottom: 8 }}>
          Vous trouverez beaucoup de logements dans Neuville et ses alentours
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Link
            label="Airbnb"
            url="https://www.airbnb.fr/s/Neuville~sur~Touques--France/homes?tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&flexible_trip_lengths%5B%5D=one_week&monthly_start_date=2023-11-01&monthly_length=3&price_filter_input_type=0&price_filter_num_nights=5&channel=EXPLORE&adults=4&query=Neuville-sur-Touques%2C%20France&place_id=ChIJ32Cn3mjy4UcRsBG4T0gUDAQ&date_picker_type=calendar&checkin=2024-08-30&checkout=2024-09-01&source=structured_search_input_header&search_type=autocomplete_click"
          />
          -
          <Link
            label="Gîtes de France"
            url="https://www.gites-de-france.com/fr/search?destination=Neuville-sur-Touques&towns=59018&travelers=2&seed=45b45682&arrival=2024-08-30&departure=2024-09-01"
          />
          -
          <Link
            label="Abritel"
            url="https://www.abritel.fr/search?adults=2&d1=2024-08-30&d2=2024-09-01&destination=Neuville-sur-Touques%2C%20Orne%20%28d%C3%A9partement%29%2C%20France&endDate=2024-09-01&flexibility=0_DAY&latLong=48.858007%2C0.283195&regionId=553248623679741244&semdtl=&sort=RECOMMENDED&startDate=2024-08-30&theme=&userIntent="
          />
          -
          <Link
            label="Booking"
            url="https://www.booking.com/accommodation/city/fr/neuville-sur-touques.fr.html?aid=318615;label=New_French_FR_FR_21427170505-oa8xWkWsUPqfDcpOzCbMcQS640938627800:pl:ta:p1:p2:ac:ap:neg;ws=&gclid=CjwKCAiAxreqBhAxEiwAfGfndMQI4C5JXNRvc1XQEFUMEnOTUuHUq9nkHY4utjl-0-o17uSWloLfqRoCHxcQAvD_BwE"
          />
        </div>
        <div
          style={{
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "center",
            padding: "0 10%",
            marginTop: 16,
          }}
        >
          <Link
            label="Hôtel les Champs, Gacé"
            url="https://www.hoteldeschampsgace.com/"
          />
          <p>
            L'hôtel les Champs, situé à Gacé à 10mn en voiture, est normalement
            fermé pour congés, mais il peut ouvrir si vous êtes suffisamment
            nombreux. <br /> Si vous êtes intéressés par une chambre dans cet
            hôtel, envoyez-nous un message rapidement.
          </p>
        </div>
      </SectionWrapper>
      <hr
        style={{
          width: "20%",
          marginBottom: 16,
        }}
      />
      <SectionWrapper>
        <span className="home-subtitle">Taxi</span>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: isMobile ? "0 5% " : "0 10%",
            alignItems: "center",
          }}
        >
          <span style={{ marginBottom: 16 }}>
            N'hésitez pas à contacter Anthony pour vos trajets en taxi pour le
            week-end !
          </span>
          <div
            style={{
              display: "flex",
              // marginTop: 8,
              alignItems: "center",
              marginBottom: 24,
            }}
          >
            {isMobile ? (
              <Link label="Anthony : 07 71 82 57 37" url="tel:0771825737" />
            ) : (
              <>
                <span
                  style={{
                    marginRight: 4,
                    // fontWeight: "bolder",
                    fontFamily: "Sofia Sans",
                    fontSize: 20,
                  }}
                >
                  Anthony :
                </span>
                <span
                  style={{
                    // fontWeight: "bolder",
                    fontSize: 20,

                    fontFamily: "Sofia Sans",
                  }}
                >
                  07 71 82 57 37
                </span>
              </>
            )}
          </div>
          <span style={{ marginBottom: 24 }}>
            Il sera disponible avec 6 places dans sa voiture la nuit du mariage
            si vous souhaitez laisser votre voiture sur place. Organisez-vous
            avec lui pour qu'il vous redépose chez nous pour le brunch !
          </span>
          <span>
            Il peut aussi venir vous chercher en gare de l'Aigle ou vous y
            déposer si vous voyagez en train. Vous pouvez l'appeler ou lui
            envoyer un message dès maintenant pour réserver vos courses en
            précisant qu'il s'agit du mariage d'Anna et Victoria.
          </span>
        </div>
      </SectionWrapper>
    </>
  );
};
