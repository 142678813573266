import { NavLink } from "react-router-dom";

// Home
// Réponses
// Lien logements + Taxi et transports
// Liste de mariage (when ready)

export const ROUTES = [
  { value: "home", path: "/home", label: "Home" },
  {
    value: "confirm",
    path: "/confirm",
    label: "Confirmez votre présence",
  },
  { value: "adresses", path: "/adresses", label: "Adresses" },
  { value: "infos", path: "/infos", label: "Infos pratiques" },
  { value: "jukebox", path: "/jukebox", label: "Jukebox" },
];

export const Navbar = () => {
  return (
    <nav
      id="navbar"
      style={{
        minWidth: 600,
      }}
    >
      {ROUTES.map((e) => (
        <>
          <NavLink
            className={({ isActive }) =>
              isActive ? "navbar-link navbar-link-is-active" : "navbar-link"
            }
            to={e.path}
          >
            {e.label}
          </NavLink>
        </>
      ))}
    </nav>
  );
};
