import { slide as Menu } from "react-burger-menu";
import { NavLink } from "react-router-dom";
import { ROUTES } from "./Navbar";
import { useCallback, useState } from "react";

const styles = {
  bmBurgerButton: {
    position: "fixed",
    width: "30px",
    height: "24px",
    left: "16px",
    top: "20px",
  },
  bmBurgerBars: {
    // background: isDarkMode ? "white" : "#373a47",
    background: "#373a47",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
    // width: '200'
  },
  bmMenu: {
    // background: '#373a47',
    background: "white",
    opacity: 0.9,
    padding: "2.5em 0 0 1.5em ",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    // padding: '0.8em',
    display: "flex",
    flexDirection: "column",
  },
  bmItem: {
    display: "inline-block",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
  },
};

export const BurgerMenu = () => {
  const [isOpen, setOpen] = useState(false);
  // const [textColor, setTextColor] = useState("black");

  const handleIsOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Menu
        isOpen={isOpen}
        onOpen={handleIsOpen}
        onClose={handleClose}
        styles={styles}
        width={320}
      >
        {ROUTES.map((e) => (
          <>
            <NavLink
              className={({ isActive }) =>
                isActive ? "navbar-link navbar-link-is-active" : "navbar-link"
              }
              onClick={handleClose}
              to={e.path}
              style={{ marginBottom: 8 }}
            >
              {e.label}
            </NavLink>
          </>
        ))}
      </Menu>
    </>
  );
};
