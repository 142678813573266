import styled from "styled-components";

//test main
export const MainContent = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  text-align: center;
  //padding: 0 20px;

  // pas sûr
  // justify-content: ${(props) => (props.$isMobile ? "center" : "flex-start")};
  justify-content: flex-start;
  padding: ${(props) =>
    props.$isMobile ? "16px 20px 16px 20px" : "30px 0 0 0"};
  height: 100%;
  box-sizing: border-box;
  background-color: #faf3f0;
`;
