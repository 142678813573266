export const Row = ({ title, children }) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        // justifyContent: "space-around",
        // border: "solid 1px blue",
        minWidth: 300,
        marginBottom: 8,
      }}
    >
      <div
        style={{
          width: 80,
          display: "flex",
          // marginRight: 50
        }}
      >
        <span>{title}</span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {children}
      </div>
    </div>
  );
};
