export const NAVBAR_HEIGHT = 56;

export const HEADER_HEIGHT = 320;
export const HEADER_HEIGHT_MOBILE = 154;

export const HEADER_IMG_HEIGHT = 280;
export const HEADER_IMG_HEIGHT_MOBILE = 100;

export const FOOTER_HEIGHT = 120;
export const FOOTER_HEIGHT_MOBILE = 70;
