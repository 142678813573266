import "./App.css";
import { Route, Routes } from "react-router-dom";

import { Layout } from "./components/Layout";
import * as routes from "./routes";
import { useState } from "react";

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  return !authenticated ? (
    <Layout>
      <Routes>
        <Route
          path="*"
          element={<routes.Login setAuthenticated={setAuthenticated} />}
        />
        <Route exact path="/confirm" element={<routes.Confirm />} />
      </Routes>
    </Layout>
  ) : (
    <Layout>
      <Routes>
        {/* TODO: */}

        <Route exact path="/home" element={<routes.Home />} />

        <Route exact path="/adresses" element={<routes.Adresses />} />
        <Route exact path="/infos" element={<routes.Infos />} />
        <Route exact path="/jukebox" element={<routes.Jukebox />} />
        <Route path="*" element={<routes.NotFound />} />
      </Routes>
    </Layout>
  );
}

export default App;
